<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">
          {{ clinic.name }}<br/>
          <small>Details</small>
        </h2>
        <a @click="$emit('edit')" class="btn btn-light btn-sm"
          ><i class="bi bi-pencil-square me-1 mb-1"></i>Edit</a
        >
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-3">
      <!--begin::Patient Section-->
      <div class="mb-10">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Clinic details:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Details-->
        <div class="d-flex flex-wrap pt-5 mb-8">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">ID:</td>
                <td class="text-gray-800 min-w-200px">
                  #{{ clinic.id }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Name:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ clinic.name }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Email:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ clinic.email }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Last update:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ new Date(clinic.updatedAt).toLocaleDateString() }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Account type:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ clinic.accountType }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Phone:</td>
                <td class="text-gray-800">{{ clinic.phone }}</td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Operation price:</td>
                <td class="text-gray-800">{{ clinic.operationPrice }}€</td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Created:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ new Date(clinic.createdAt).toLocaleDateString() }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Details-->
        <div class="separator separator-dashed mb-10"></div>
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Address:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Address-->
        <div class="d-flex flex-wrap pt-5 mb-10">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Street:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ defaultAddress.street }}
                  <template v-if="defaultAddress.street2">
                    <br>{{ defaultAddress.street2 }}
                  </template>
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">City:</td>
                <td class="text-gray-800">{{ defaultAddress.city }}</td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Country:</td>
                <td class="text-gray-800">{{ defaultAddress.country }}</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">ZIP:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ defaultAddress.zip }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">State:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ defaultAddress.state }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Address-->
        <div class="separator separator-dashed mb-10"></div>

        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Third-party services:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Address-->
        <div class="d-flex flex-wrap pt-5 mb-10">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Mollie Customer ID:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ clinic.mollieCustomerId || "N/C" }}
                  <a
                    v-if="clinic.mollieCustomerId"
                    :href="`https://my.mollie.com/dashboard/org_15737838/customers/${clinic.mollieCustomerId}`"
                    target="_blank"
                  >
                    <i class="bi bi-box-arrow-up-right mr-1"></i>
                  </a>
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Gmail account linked:</td>
                <td class="text-gray-800">{{ clinic.gmailApiToken ? 'Yes' : 'No' }}</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">Appear on website:</td>
                <td class="text-gray-800">{{ clinic.appearOnSite ? 'Yes' : 'No' }}</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Address-->
      </div>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
  },
  computed: {
    defaultAddress() {
      return this.clinic.addresses.find(e => e.label == "DEFAULT");
    },
  },
};
</script>
